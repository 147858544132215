import React, { useEffect, useRef, useState } from "react";
import Page from "../../components/page";
import Header from "../../components/header";
import Footer from "../../components/footer";
import FaqQuestion from "../../components/faq-question";
import faqClasses from "./faq.module.scss";
import BoxImage from "../../components/slider-images/box-image";
import Button from "../../components/button";
import ContactUs from "../../components/contact-us";
import AOS from "aos";
import "aos/dist/aos.css";
import { ANIMATION_TYPE } from "../../constants/animation";
import { Fade } from "react-awesome-reveal";
import CookieBanner from "../../components/cookie-banner/index";
import {
  KEY_ACCEPTED_COOKIES,
  QUESTIONS_KE_KE,
} from "../../constants/homepage";
import { getAcceptedCookie } from "../../services/acceptedCookie";
import { Link } from "gatsby";
function Index({ location }) {
  const [isAcceptedCookie, setIsAcceptedCookie] = useState(
    getAcceptedCookie(KEY_ACCEPTED_COOKIES)
  );
  useEffect(() => {
    AOS.init();
    return () => {
      AOS.refresh();
    };
  }, []);
  return (
    <Page className={faqClasses.faq}>
      <main>
        <div className={faqClasses.container}>
          <Header />
        </div>
        <Fade cascade direction={"bottom"} damping={0.1}>
          <section data-aos={ANIMATION_TYPE}>
            <FaqQuestion
              typePage="faqpage"
              isFaqPage
              location={location}
              typeQuestions={QUESTIONS_KE_KE}
            />
          </section>

          <div className={faqClasses.faqRectangle}>
            <div className={faqClasses.txt}>
              <h2>Skúste Doruč.to</h2>
              <p className={faqClasses.p}>
                Nulla pulvinar nec lectus sit amet luctus. Ut nisi neque,
                gravida nec dapibus et, pulvinar sit amet felis. Suspendisse
                lacinia.
              </p>
              <div className={faqClasses.btn}>
                <Button className={faqClasses.button}>
                  <Link to="/ke-cz" className={faqClasses.link}>
                    Objednať z Košíc do Česka
                  </Link>
                </Button>
                <Button className={faqClasses.button}>
                  <Link to="/ke-ke" className={faqClasses.link}>
                    Objednať v rámci Košíc
                  </Link>
                </Button>
              </div>
            </div>
            <div className={faqClasses.boxImg}>
              <BoxImage />
            </div>
          </div>
          <section>
            <ContactUs />
          </section>
        </Fade>
        <Footer />
        {isAcceptedCookie === null ? (
          <CookieBanner setIsAcceptedCookie={setIsAcceptedCookie} />
        ) : (
          ""
        )}
      </main>
    </Page>
  );
}

export default Index;
